
import Vue from 'vue';
import axios from '../../../plugins/axios.js';
import { Provide, Component, Prop, Ref } from 'vue-property-decorator';

@Component
export default class AddPartner extends Vue {
    // 机构所能使用的模板课程
    @Prop() private templateCourses!: any;
    @Prop() private addressesOptions;
    @Provide() private showDialog: boolean = false;
    @Provide() private partner: { [index: string]: any } = {
        account: '',
        uuid: '',
        nickname: '',
        partnerName: '',
        partnerCode: '',
        username: '',
        memberQuota: 0,
        problemAccessQuota: 0,
        problemSetAccessQuota: 0,
        description: '',
        contractTime: [],
        address: [],
    };
    @Provide() private rules: { [index: string]: object[] } = {
        account: [
            { required: true, message: '请填写计蒜客账号', trigger: 'blur' },
            { validator: this.validateAccount, trigger: 'blur' },
        ],
        partnerName: [
            { required: true, message: '请填写机构名', trigger: 'blur' },
        ],
        address: [
            { required: true, message: '请填写机构地理位置', trigger: 'blur' },
        ],
        partnerCode: [
            { required: true, message: '请填写机构代码', trigger: 'blur' },
            { validator: this.validatePartnerCode, trigger: 'blur' },
        ],
        username: [
            { required: true, message: '请填写蒜壳用户名', trigger: 'blur' },
            { validator: this.validateSuankeUserName, trigger: 'blur' },
        ],
        memberQuota: [
            { required: true, message: '请填写人员数量上限', trigger: 'blur' },
        ],
    };
    @Ref() private readonly partnerForm;

    private created() {
        this.$on('open', () => {
            this.showDialog = true;
        });
    }

    private isLegalPartnerCode() {
        return this.partner.partnerCode && (/^\w*$/g).test(this.partner.partnerCode);
    }
    private updateDatePicker() {
        this.$forceUpdate();
    }
    private validateAccount(rule: any, value: any, callback: any) {
        axios.get('/api/v1/partner-manage/user', {
            account: this.partner.account,
        }).then(({ uuid, name, partnerCode, username }) => {
            this.partner.uuid = uuid;
            this.partner.nickname = name;
            this.partner.partnerCode = partnerCode;
            this.partner.username = username;
        }).catch((error) => {
            this.$message.error(error);
        });
        return callback();
    }
    private validatePartnerCode(rule: any, value: any, callback: any) {
        if (!(/^\w*$/g).test(value)) {
            return callback(new Error('机构代码仅支持大小写字母、数字、下划线的组合'));
        }
        return callback();
    }
    private validateSuankeUserName(rule: any, value: any, callback: any) {
        if (!(/^\w*$/g).test(value)) {
            return callback(new Error('蒜壳用户名仅支持大小写字母、数字、下划线的组合'));
        }
        return callback();
    }

    private clearValidate() {
        this.partnerForm.clearValidate();
    }

    private submit() {
        this.partnerForm.validate((valid) => {
            if (!valid) {
                return false;
            }
            axios.post('/api/v1/partner-manage/create-partner', {
                ...this.partner,
                province: this.partner.address[0],
                city: this.partner.address[1],
                district: this.partner.address[2],
                startTime: this.partner.contractTime[0],
                expireTime: this.partner.contractTime[1],
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '添加机构成功',
                });
                this.clearForm();
                this.showDialog = false;
                this.$emit('refresh-partners');
            }).catch((error) => {
                this.$message.error(error);
            });
        });
    }

    private clearForm() {
        this.partner = {
            account: '',
            uuid: '',
            nickname: '',
            partnerName: '',
            partnerCode: '',
            username: '',
            memberQuota: 0,
            problemAccessQuota: 0,
            problemSetAccessQuota: 0,
            description: '',
            contractTime: [],
            address: [],
        };
    }
}
